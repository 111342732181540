<template>
  <div class="relative inline-block w-full">
    <div
      v-if="$slots.label && labeltop"
      class="mb-1 block font-semibold"
    >
      <slot name="label" />
    </div>
    <template v-if="$slots.icon">
      <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
        <slot name="icon" />
      </span>
    </template>
    <div
      :class="[{'pl-11 pr-6': $slots.icon},
               {'px-4': !$slots.icon && !xs},
               {'py-3 h-[51.59px]': normal && !$slots.label},
               {'py-2 ': slim && !$slots.label},
               {'py-1 px-1': xs && !labeltop && !$slots.icon},
               {'py-3 text-base h-[55px]': normal && labeltop && $slots.label},
               {'py-3 text-base': slim && labeltop && $slots.label},
               {'pt-6 pb-1 text-lg font-semibold h-[55px] peer': normal && labelinside && $slots.label},
               {'pt-3 pb-1 text-base font-semibold peer': slim && labelinside && $slots.label},
               {'rounded-xl w-full': !props.inputstyle},
               {'border-neutral-200 bg-white': !error},
               {'border-cta-700 bg-cta-50': error},
               'border text-left focus:outline-none focus:border-petrol-500 focus:ring-1 focus:ring-petrol-500 block active-input',
               props.inputstyle]"
    >
      <div
        v-if="$slots.label && labelinside"
        :class="[
          {'absolute -mt-3 ml-4': labelup},
          {'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-100 -translate-y-1': (slim && !props.modelValue) && !labelup},
          {'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-[75%] -translate-y-3.5': (slim && props.modelValue) && !labelup},
          {'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-100 translate-y-0': (normal && !props.modelValue) && !labelup},
          {'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 -translate-y-2.5 scale-[85%]': (normal && props.modelValue) && !labelup},
          'left-0'
        ]"
      >
        <slot name="label" />
      </div>
      <div v-if="props.modelValue" class="absolute truncate input-text-width">
        {{ props.modelValue }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  label: {
    type: String,
    default: ''
  },
  labelPosition: {
    type: String,
    default: 'inside'
  },
  accessibilityLabel: {
    type: String,
    default: ''
  },
  inputstyle: {
    type: [String, Array],
    default: ''
  },
  placeholder: {
    type: String,
    default: ' '
  },
  name: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  title: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: Boolean,
    default: true
  }
})
const normal = computed(() => props.design === 'normal')
const slim = computed(() => props.design === 'slim')
const xs = computed(() => props.design === 'xs')

// Label choices
const labelup = computed(() => props.label === 'up')
const labeltop = computed(() => props.labelPosition === 'top')
const labelinside = computed(() => props.labelPosition === 'inside')

defineEmits<{(e: 'update:modelValue', value: string): void}>()
</script>

<style lang="scss" scoped>
.input-text-width {
  width: calc(100% - 4.25rem);
}
.active .active-input{
  @apply outline-none border-petrol-500 ring-1 ring-petrol-500
}
</style>
